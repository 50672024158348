.fab-button{
    bottom: 125px;
    border-radius: 100px; 
    width: 40px;
    height: 40px;
    line-height: 40px;
    position: fixed;
    right: 30px;
    font-size: 15px;  
    z-index: 9;
    color: var(--tg-white);
    text-align: center;
    cursor: pointer;
    background: var(--tg-primary-color);
    transition: 1s ease;
    border: none;
}

.fab-options{
    bottom: 170px;   
    line-height: 45px;
    position: fixed;    
    right: 30px;
    font-size: 15px;   
    color: var(--tg-white); 
    cursor: pointer;    
    transition: 1s ease;
    border: none;
}

.fab-options .fab-option i{
    border-radius: 100px;
    width: 38px;
    height: 38px;   
    text-align: center;
    line-height: 38px;
    color: var(--tg-white); 
}

.fab-options .fab-option .face{ 
    background: #3B5999;
}

.fab-options .fab-option .twit{ 
    background: #1DA1F3;
}

.fab-options .fab-option .insta{   
    background:  radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf);;
}

.rotate-48 {
    transform: rotate(48deg);
    transition: transform 1s ease; 
  }
  
  .fab-options .fab-option .youtube{ 
    background: #EC2929;
}
.fab-options .fab-option .linked{ 
    background: #0274B3;
}
.fab-options .fab-option .pin{ 
    background: #BD081B;
}

@media (max-width: 767.98px) {
    .fab-button {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
    }
    .fab-button .open {
        bottom: 15px;
    }
    .fab-button {
        right: 18px;
        bottom: 86px;
    }
    .fab-options{
    line-height: 35px;
    }
    .fab-options .fab-option i {   
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
    }
    .fab-options .open {
        bottom: 15px;
    }
    .fab-options {
        right: 18px;
        bottom: 125px;
    }
a .whatsapp-button{
    bottom: 50px;
    border-radius: 100px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    position: fixed;
    right: 17px;
    font-size: 13px;
}
}

.whatsapp-button{
    bottom: 77px;
    border-radius: 100px; 
    width: 40px;
    height: 40px;
    line-height: 40px;
    position: fixed;
    right: 30px;
    font-size: 18px;  
    z-index: 9;
    color: var(--tg-white);
    text-align: center;
    cursor: pointer;
    background: #25D366;
    transition: 1s ease;
    border: none;
}

.insta_post{
    width: 100%;
}

.tb_hs_media_wrap img{
    height: 100px !important;
}
.brand_city{
    color:var(--tg-black);
}

img.tb_sp_image {
    border-radius: 15px;
    padding: 5px;
}
/* error page */
.error-size {
    font-size: 9.5rem;
    color: #d0ad6b;
  }

  /* errore page end */

  /* header hover */
  .social-links ul li a i {
    transition: all 1s ease-in-out;  
}

 .social-links ul li a  :hover{   
    color: #fff;
    transform: scale(1.3);
    transition: all .3s ease-in-out;
  } 

.footer-social .list-wrap li a:hover i {
    color: #fff; /* Change icon color on hover */
    transform: scale(1.2); /* Scale up the icon on hover */
}

.footer-social .list-wrap li a i {
    transition: all .3s ease-in-out; /* Apply transition effect to icons */
}

.video-w{
    z-index:0;
}

/* notification popup */
.notification-popup{
    padding: 0 !important;
    display: flex;
    justify-content: flex-end;
}

.close-icon{
    font-size: 18px;
    position: absolute;
    color: #fff;
    padding: 8px;
    cursor: pointer;
}



/* instagram css */
.instagram-gallery {
    padding: 40px 0;
    background-color: #f9f9f9;
  }
  
  .gallery-item {
    margin-bottom: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .media-container {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .gallery-image {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease-in-out;
    border-radius: 10px;
  }
  
  .media-container:hover .gallery-image {
    transform: scale(1.1);
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }
  
  .media-container:hover .overlay {
    opacity: 1;
  }
  
  .overlay i {
    color: white;
    font-size: 2.5rem;
  }
  
  .slick-slide img {
    display: block;
    width: 100% !important;
    height: auto !important;
}